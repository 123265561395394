var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "question-list-page" },
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: { "page-title": !_vm.$language ? "Questions" : "প্রশ্নপত্র" },
      }),
      _c("question-list", { attrs: { "hide-actions": false } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }