<template>
  <m-container class="question-list-page">
    <page-title
      :page-title="!$language ? 'Questions' : 'প্রশ্নপত্র'"
      class="mt-5"
    ></page-title>
    <question-list :hide-actions="false"></question-list>
  </m-container>
</template>

<script>
import QuestionList from "#ecf/question-bank/components/QuestionList";

export default {
  name: "QuestionListPage",
  components: { QuestionList },
  // components: { AnswersColumn },
  data: () => ({})
};
</script>
